import * as Sentry from "@sentry/browser";
import mixpanel from 'mixpanel-browser';
import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";
var analytics;

console.log('Initializing analytics module');

class Analytics {
  constructor() {
    if (!Analytics.instance) {
      this.initSentry();
      this.initMixpanel();
      this.analyticsBrowser = this.initAnalyticsBrowser();
      Analytics.instance = this;
    }

    return Analytics.instance;
  }

  initSentry() {
    Sentry.init({
      dsn: "https://3fdb0c41327a83e4cb56d5b4b90581d0@o4506895983706112.ingest.us.sentry.io/4506895986524160",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    });
    console.log('Sentry initialized');
  }

  initMixpanel() {
    mixpanel.init('cf94a02e44ddf7e05d5845558f9f89df', {debug: true, track_pageview: true, persistence: 'localStorage'});
    console.log('Mixpanel initialized');
  }

  initAnalyticsBrowser() {
    analytics = AnalyticsBrowser.load({ writeKey: "b697936a820366b49025" });
    console.log('AnalyticsBrowser initialized');
    return analytics;
  }
}

const analyticsInstance = new Analytics();
Object.freeze(analyticsInstance);

// Export Sentry, mixpanel, and the analyticsBrowser instance
export { Sentry, mixpanel, analytics };
