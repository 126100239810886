
//
function getSmartRecurringReminderPacket(CURR_USER, CURR_USERPROFILE, reminderSchedule, reminderMessage){
    console.log("TODO: We need to build out a reminder schedule for this - that gets updated based on the prompts settings too - one less option ");
    var goodToRemind_epoch = calculateNextReminderTime(reminderSchedule);
    if (goodToRemind_epoch === null){
        return false;
    }
    var reminderPacket = {
        owner_uid: CURR_USER.uid,
        owner_name: CURR_USERPROFILE.firstname,
        target_phonenumber: CURR_USER.phoneNumber,
        target_email: CURR_USERPROFILE.email,
        is_recurring: false,
        sent: false,
        next_reminder_epoch: goodToRemind_epoch, // will be id of the document too
        next_reminder: new Date(goodToRemind_epoch).toISOString(), //
        recurrence: reminderSchedule,
        message: reminderMessage,
        target_url: "https://learner.plus/?fromReminder=true",
    }
    return reminderPacket;
}

function calculateNextReminderTime(reminderType) {
    const currentDate = new Date();
    switch (reminderType) {
      case 'surprise':
        currentDate.setDate(currentDate.getDate() + 5); // 5 dates from now
        break;
      case 'tomorrow':
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case 'nextweek':
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case 'nextweekend':
        const dayOfWeek = currentDate.getDay();
        // If it's Sunday (0), add 6 days to get to next Saturday; otherwise, just add until Saturday (6)
        const daysToAdd = dayOfWeek === 0 ? 6 : 6 - dayOfWeek;
        currentDate.setDate(currentDate.getDate() + daysToAdd);
        break;
      case 'eom':
        // Set to the first day of the next month, then subtract one day to get to the end of the current month
        currentDate.setMonth(currentDate.getMonth() + 1, 1);
        currentDate.setDate(currentDate.getDate() - 1);
        break;
      case 'nextmonth':
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case '3months':
        currentDate.setMonth(currentDate.getMonth() + 3);
        break;
      case '6months':
        currentDate.setMonth(currentDate.getMonth() + 6);
        break;
      case 'never':
      default:
        // Optionally, return null or keep the date as is to indicate no further action
        return null;
    }
  
    // Round to the nearest hour
    let hour = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    if (minutes >= 30) {
      hour++;
    }
    currentDate.setHours(hour, 0, 0, 0);
  
    return currentDate.getTime();
}
  
export { getSmartRecurringReminderPacket, calculateNextReminderTime };