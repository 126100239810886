import { Sentry, mixpanel, analytics } from '../lib/analytics';

var jquery = require("jquery");
$ = window.$ = window.jQuery = jquery; // notice the definition of global variables here

import LearnerPlusTranscriptFeatureImagePath from '../img/LearnerPlusTranscriptFeature.png';

import axios from 'axios';

import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'
const { toggle } = createPopup('<form-id>');

//
const Mustache = require('mustache');
//
import firebase from "firebase/app";

import "firebase/auth";
import "firebase/analytics";
import "firebase/database";
import "firebase/firestore";

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

// import our utils
import { getSmartRecurringReminderPacket } from '../lib/reminderutils';
import { getQueryParamsAsObject, getQueryParamsAsString, removeURLParameters } from "../lib/util-standardfunctions";


//
if(window.document.documentMode){
    document.getElementById('main-preloader').innerHTML = '<div class="col s8 offset-s2"><h3>This browser is not supported. Please use Apple Safari, Mozilla Firefox, Microsoft Edge or Google Chrome</h3></div>';
}
if (window.location.href.indexOf("http://localhost") === -1){
    window.console.log = function(){
        console.info('© 2024 SCAD.Ventures');
        window.console.log = function() {
            return false;
        }
    }
}

!(function() {
    var CURR_USER = false;
    var CURR_USERPROFILE = false;
    var CURR_STRIPESTATUS = false;
    var CURR_STRIPEPACKET = false;
    var CURR_LEARNERSTATE = {
        creditBalance: 0,
        reflections: [],
        reflection_ids: [],
        certificates: []
    }
    var CURR_REFLECTIONKIT = false;
    
    //
    var CURR_QueryParams_Str = getQueryParamsAsString();
    var CURR_QueryParams_Obj = getQueryParamsAsObject();
    console.log("CURR_QueryParams_Obj", CURR_QueryParams_Obj);
    // add a function that checks and removes the console if the URL is not localhost
    removeURLParameters(Object.keys(CURR_QueryParams_Obj));
    console.log("MIJI JSKJM!");
    //
    const firebaseConfig = {
        apiKey: "AIzaSyAkOAXn8FEH1PKjf_aghihKEamNddBQB18",
        authDomain: "learner-plus.firebaseapp.com",
        projectId: "learner-plus",
        storageBucket: "learner-plus.appspot.com",
        messagingSenderId: "812434383550",
        appId: "1:812434383550:web:d2cda1285d3e3199a134ef"
    };
    
    // Initialize Firebase
    showLoadingOverlay("Loading Learner+");
    //
    console.log("%cLearner+ vs Learner Hub free -- we need to hide reflect now button for non-subscribers (stripe check)", "color:red; background:white; font-size:1.26em;")
    //
    const app = firebase.initializeApp(firebaseConfig);
    firebase.auth().onAuthStateChanged((user)=>{
        console.log(user);
        if (user){
            // we have a user
            console.log(user.uid);
            UI_BindActions();
            //
            CURR_USER = user;
            // we see if the profile is filled out otherwise move forward
            firebase.firestore().collection('userprofiles').doc(user.uid).get().then((doc)=>{
                if (doc.exists){
                    showLoadingOverlay("Loading your dashboard, certificates and more...");
                    CURR_USERPROFILE = doc.data();
                    CURR_REFLECTIONKIT = "reflective-practice";
                    if (CURR_USERPROFILE.reflectionkits){
                        CURR_REFLECTIONKIT = CURR_USERPROFILE.reflectionkits[0]?CURR_USERPROFILE.reflectionkits[0]: "reflective-practice";                    
                    }
                    //
                    Sentry.setContext("CURR_USER", CURR_USER);
                    Sentry.setContext("CURR_USERPROFILE", CURR_USERPROFILE);
                    Sentry.setContext("CURR_REFLECTIONKIT", CURR_REFLECTIONKIT);
                    //
                    mixpanel.identify(CURR_USER.uid);
                    mixpanel.people.set({
                        "$email": CURR_USERPROFILE.email,
                        "$name": CURR_USERPROFILE.firstname + " " + CURR_USERPROFILE.lastname,
                        "credential": CURR_USERPROFILE.credential,
                    });
                    mixpanel.track('Learner+ Hub Visit', {CURR_QueryParams_Obj});
                    //
                    document.getElementById('user-phonenumber').innerHTML = CURR_USER.phoneNumber;
                    document.getElementById('user-email').innerHTML = CURR_USERPROFILE.email;
                    //
                    CURR_STRIPESTATUS = false;
                    firebase.firestore().collection('stripe').doc(CURR_USER.uid).get().then((doc)=>{
                        if (doc.data()){
                            var stripePacket = doc.data();
                            CURR_STRIPEPACKET = stripePacket;
                            if (stripePacket.subscription_status === 'active'){
                                CURR_STRIPESTATUS = true;
                            }
                        }
                        InitiateMainApp();
                    }).catch(stripeErr=>{
                        Sentry.captureException(stripeErr);
                        CURR_STRIPESTATUS = false;
                        InitiateMainApp();
                    });
                }else{
                    console.log("issue with doc in the userprofiles section");
                    window.location.href = '/login?go=hub';
                }
            }).catch((error)=>{
                showNotification('Profile Fetch Error', 'There was an error fetching your profile - '+error+'.', 'error', 3000);
                window.location.href = '/login?go=hub';
            });
            //
        }else{
            // no user
            console.log("issue with user logged in");
            var includeSubscriptionHook = '';
            if (CURR_QueryParams_Obj.navigate_to_subscribe){
                includeSubscriptionHook = '&navigate_to_subscribe=true';
            }
            window.location.href = `/login?go=hub${includeSubscriptionHook}`;
        }
    });

    function setRewardful(){
        try{
            if (CURR_QueryParams_Obj.subscription === "success"){
                rewardful('ready', function() {
                    if(Rewardful.referral) {
                        // The current website visitor is a referral from an affiliate.
                        rewardful('convert', { email: CURR_USERPROFILE.email})
                    }
                });
            }
        }catch(ex){
            
        }
    }

    function InitiateMainApp(){
        //
        hideLoadingOverlay();
        // we should fetch the user
        setProfileUI();
        //
        RefineViewForReflectionKit();
        // let's update the stripe settings
        if (!CURR_STRIPESTATUS){
            console.log("Not a stripe subscriber");
            document.querySelectorAll('.subscribed-element').forEach((element)=>{
                element.remove();
            });
            document.querySelector('#subscription-status-btn').innerHTML = "Upgrade your account";
        }else{
            // subscriber
            document.querySelectorAll('.unsubscribed-element').forEach((element)=>{
                element.remove();
            });
            document.querySelector('#subscription-status-btn').innerHTML = "Manage Subscription";
        }
        // setup rewardful
        setRewardful();
        // we should fetch the credit balance
        fetchReflectionsAndCreditBalance((creditBalance)=>{
            document.querySelectorAll('.display-credits-earned').forEach((element)=>{
                element.innerHTML = creditBalance.totalUnclaimedCredts + ".0 credit"+(creditBalance.totalUnclaimedCredts>1?'s':'');
                element.classList.remove('hidden');
            });
            //
            if (creditBalance.totalUnclaimedCredts > 0){
                document.querySelectorAll('.claim-credit-ui').forEach((element)=>{
                    element.classList.remove('hidden');
                })
            }else{
                document.querySelectorAll('.claim-credit-ui').forEach((element)=>{
                    element.classList.add('hidden');
                })
            }
            //
            // analytics call
            try{
                analytics.identify(CURR_USER.uid, {
                    totalCreditsEarnedAndClaimed: creditBalance.totalCredits,
                    currentUnclaimedCredits: creditBalance.totalUnclaimedCredts,
                    sampling_reflectiveContexts: creditBalance.reflectiveContexts.slice(0, Math.min(3, creditBalance.reflectiveContexts.length)),
                    sampling_reflectiveCategories: creditBalance.reflectiveCategories.slice(0, Math.min(3, creditBalance.reflectiveCategories.length)),
                });
            }catch(ex){
                Sentry.captureException(ex);
            }
            //
            displayCurrentReflections();
        });
        // we should show the certificates balance
        fetchCertificates((certificatePacket)=>{
            if (document.getElementById('claimed-certificates'))
                document.getElementById('claimed-certificates').innerHTML = '';
            // we should show the certificates
            if (certificatePacket?.certificates?.length === 0){
                if (document.getElementById('claimed-certificates'))
                    document.getElementById('claimed-certificates').innerHTML = '<div class="text-left text-gray-500 text-xs uppercase whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0">No certificates claimed yet</div>';
            }
            //
            certificatePacket?.certificates?.forEach((certificate)=>{
                var certViewPacket = certificate;
                const certDateOptions = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute:'numeric' };
                certViewPacket.claimed_on_date = new Date(certificate.claimedOn).toLocaleString('en-US', certDateOptions);
                certViewPacket.creditsClaimed = certificate.creditCount + ".0";
                document.getElementById('claimed-certificates').innerHTML += Mustache.render(document.getElementById('certificate-entry').innerHTML, certViewPacket);
            });
            //
            try{
                analytics.identify(CURR_USER.uid, {
                    totalCertsGenerated: certificatePacket?.certificates?.length || 0
                });
            }catch(ex){
                Sentry.captureException(ex);
            }
        });
        //
        mixpanel.track('Learner+ Hub Initialized', {CURR_USERPROFILE, CURR_STRIPESTATUS, CURR_STRIPEPACKET});
        var customerIoPacket = JSON.parse(JSON.stringify(CURR_USERPROFILE)); // graceful clone
        customerIoPacket.phoneNumber = CURR_USER.phoneNumber;
        customerIoPacket.stripe_status = CURR_STRIPESTATUS;
        if (CURR_STRIPEPACKET){
            customerIoPacket.stripe_subscription = CURR_STRIPEPACKET.subscription || "none";
            customerIoPacket.stripe_customer = CURR_STRIPEPACKET.customer || "no customer id";
            customerIoPacket.stripe_subscription_status = CURR_STRIPEPACKET.subscription_status || "no status";
        }
        customerIoPacket.rewardful_affiliate = CURR_USERPROFILE.rewardful_affiliate?.id || "no affiliate";
        analytics.identify(CURR_USER.uid, customerIoPacket);
        analytics.track('learnerplus_hub_initialized', {CURR_USERPROFILE, CURR_STRIPESTATUS, CURR_STRIPEPACKET});
        //
        if (CURR_QueryParams_Obj.navigate_to_subscribe){
            performTask('smart-manage-subscription');
        }
        //
        if (CURR_QueryParams_Obj.fromReminder){
            // SMS featureset https://learner.plus/?fromReminder=true
            performTask('start-default-reflection');
        }
    }

    function setProfileUI(){
        console.log(CURR_USERPROFILE);
        document.getElementById('first-name').value = CURR_USERPROFILE.firstname;
        document.getElementById('last-name').value = CURR_USERPROFILE.lastname;
        setSelectValue(CURR_USERPROFILE.credential, 'profile_credential');
        document.getElementById('profile_specialty').value = CURR_USERPROFILE.specialty||"";
        setSelectValue(CURR_USERPROFILE.primarylocation, 'profile_primarylocation');
        if (CURR_USERPROFILE.next_reflection_reminder){
            setSelectValue(CURR_USERPROFILE.primarylocation, 'profile_preferences-reminders');
        }
        //
        if (CURR_USERPROFILE.reflectce){
            document.getElementById('legacy_learnerhub')?.classList.remove('hidden');
        }
        //
        if (CURR_QueryParams_Obj.learnerhub){
            document.querySelectorAll('.unsubscribed-element').forEach((element)=>{
                element.classList.add("hidden");
            });

            document.getElementById('learnerplus-logo').innerHTML = `<span class="text-white font-bold text-md">Learner Hub</span>`;
        }
        
        // affiliate stuff @06-21-2024
        if (CURR_USERPROFILE.rewardful_affiliate){
            document.getElementById('my-affiliate-link').innerHTML = CURR_USERPROFILE.rewardful_affiliate?.links[0]?.url;
        }else{
            Rewardful_CreateAffiliate((affiliatePacket)=>{
                if (affiliatePacket.error){
                    return; // no error to be notified here.
                    //return showNotification("Affiliate Creation Error", "There was an error creating your affiliate account - "+affiliatePacket.error, "error", 3000);
                }
                document.getElementById('my-affiliate-link').innerHTML = affiliatePacket?.links[0]?.url;
            });
        }
    }

    function updateProfile(callback){
        CURR_USERPROFILE.firstname = document.getElementById('first-name').value;
        CURR_USERPROFILE.lastname = document.getElementById('last-name').value; 
        CURR_USERPROFILE.credential = document.getElementById('profile_credential').value;
        CURR_USERPROFILE.specialty = document.getElementById('profile_specialty').value;
        CURR_USERPROFILE.primarylocation = document.getElementById('profile_primarylocation').value;
        CURR_USERPROFILE.next_reflection_reminder = document.getElementById('profile_preferences-reminders').value;
        //
        if (CURR_USERPROFILE.next_reflection_reminder){
            if (CURR_USERPROFILE.next_reflection_reminder !== 'never'){
                var reminderPacket = getSmartRecurringReminderPacket(CURR_USER, CURR_USERPROFILE, CURR_USERPROFILE.next_reflection_reminder, 'Ready to reflect?');
                if (reminderPacket){
                    updateReminderSchedule(reminderPacket, (reminderUpdateError)=>{});
                }
            }
        }
        //
        firebase.firestore().collection('userprofiles').doc(CURR_USER.uid).set(CURR_USERPROFILE).then(()=>{
            showNotification('Profile Updated', 'Your profile has been updated.', 'success', 3000);
            return callback();
            //
        }).catch((error)=>{
            showNotification('Profile Update Error', 'There was an error updating your profile - '+error+'.', 'error', 3000);
            return callback({error});
        });
    }

    
    function deletePreviousReminder(reminderPacket, callback){
        console.log(reminderPacket);
        firebase.firestore().collection('reminders').where('owner_uid', '==', reminderPacket.owner_uid).get().then((querySnapshot)=>{
            querySnapshot.forEach((doc)=>{
                doc.ref.delete();
            });
            callback()
        }).catch((error)=>{
            Sentry.captureException(error);
            callback();            
        });
    }

    function updateReminderSchedule(reminderPacket, callback){
        console.log('updating reminder schedule', reminderPacket);
        // we must remove the old reminder if it exists
        deletePreviousReminder(reminderPacket, function(){
            firebase.firestore().collection('reminders').doc(`r${reminderPacket.next_reminder_epoch}`).set(reminderPacket).then(()=>{
                console.log('reminder saved');
                return callback();
                //
            }).catch((error)=>{
                console.error('Error saving reminder: ', error);
                return callback({error});
            });    
        });
    }
    
    
    // uses the current reflections (unclaimed credits) to display in Journal
    function displayCurrentReflections(){
        document.getElementById('current-reflections').innerHTML = "";
        //reflection-journal-entry-reflective-practice or reflection-journal-entry-reflective-credit-marquette
        var reflectionTemplate = document.getElementById('reflection-journal-entry-'+CURR_REFLECTIONKIT).innerHTML;
        for (var i=0; i<CURR_LEARNERSTATE.reflections.length; i++){
            var refViewPacket = CURR_LEARNERSTATE.reflections[i];
            refViewPacket.reflected_on_date = new Date(refViewPacket.reflectedOn).toDateString();
            document.getElementById('current-reflections').innerHTML += Mustache.render(reflectionTemplate, refViewPacket);
        }
    }

    function RefineViewForReflectionKit(){
        switch (CURR_REFLECTIONKIT){
            case "reflective-credit-marquette":
                document.querySelector('#lrnrpls_journal').classList.remove('hidden');
                var buttonToReflect = document.createElement('a');
                buttonToReflect.innerHTML = "Reflect Again<i class='fa-solid fa-chevron-right ml-2 text-white'></i>";
                buttonToReflect.classList.add('inline-block', 'cursor-pointer', 'mb-8', 'text-lg', 'px-4', 'py-2', 'text-white', 'bg-learner-dark-blue', 'rounded', 'shadow-md', 'hover:bg-learner-dark-blue/70', 'hover:shadow-lg');
                buttonToReflect.onclick = function(){
                    window.location.href = '/'+CURR_REFLECTIONKIT;
                }
                document.querySelector('#lrnrpls_journal').insertBefore(buttonToReflect, document.querySelector('#lrnrpls_journal').firstChild);
                document.querySelectorAll('.hide-for-kit-reflective-credit-marquette').forEach((element)=>{
                    element.remove();
                });
                break;
            default:
                // reflective-practice - do no thing :) 
                break;
        }
    }

    // fetches the reflections and credit balance
    function fetchReflectionsAndCreditBalance(callback){
        firebase.firestore().collection('reflections').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).get() // Use the count aggregation query
            .then((docSnapshot) => {
                var totalUnclaimedCredts = 0;
                var totalCredits = 0;
                var reflectiveContexts = [];
                var reflectiveCategories = [];
                docSnapshot.docs.forEach((doc)=>{
                    totalCredits += doc.data().credit;
                    reflectiveContexts.push(doc.data().ipoc_context);
                    reflectiveCategories.push(doc.data().reflection_based_reflective_category || "General Reflection");
                    if (!doc.data().claimedOn){
                        totalUnclaimedCredts += doc.data().credit;
                        CURR_LEARNERSTATE.reflections.push(doc.data());
                        CURR_LEARNERSTATE.reflection_ids.push(doc.id);
                    }
                });
                //
                CURR_LEARNERSTATE.creditBalance = totalUnclaimedCredts;
                CURR_LEARNERSTATE.reflections = CURR_LEARNERSTATE.reflections.sort((a, b) => (a.reflectedOn > b.reflectedOn) ? -1 : 1);
                //
                reflectiveContexts = [...new Set(reflectiveContexts)];
                reflectiveCategories = [...new Set(reflectiveCategories)];
                //
                return callback({totalUnclaimedCredts, totalCredits, reflectiveContexts, reflectiveCategories});
            })
            .catch((error) => {
              console.error('Error getting document count: ', error);
              return callback({error});
            });
    }

    // fetches certificates
    function fetchCertificates(callback){
        firebase.firestore().collection('certificates').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).get()
            .then((docSnapshot) => {
                CURR_LEARNERSTATE.certificates = [];
                docSnapshot.docs.forEach((doc)=>{
                    var cert = doc.data();
                    cert.certificateId = doc.id;
                    CURR_LEARNERSTATE.certificates.push(cert);
                });
                //
                CURR_LEARNERSTATE.certificates.sort((a,b)=>{
                    return b.claimedOn-a.claimedOn;
                })
                //
                return callback({certificates: CURR_LEARNERSTATE.certificates});
            })
            .catch((error) => {
              console.error('Error getting document count: ', error);
              return callback({error});
            });
    }

    // Evaluation & Certificate
    var tfPopup = null;
    function GenerateAndDownloadCertificate(certificateId){
        firebase.firestore().collection('certificates').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(certificateId).get().then((doc)=>{
            if (doc.exists){
                mixpanel.track('Downloading certificate', {CURR_USERPROFILE, certificateId});
                analytics.track('learnerplus_certificate_download', {CURR_USERPROFILE, certificateId});
                showNotification("Downloading certificate...", "Check your downloads folder or ensure that your browser is not blocking downloads","",7200)
                var creditGenPacket = {
                    learner_name: CURR_USERPROFILE.firstname + " " + CURR_USERPROFILE.lastname,
                    credits_claimed: doc.data().creditCount,
                    credits_claimedon: (new Date(doc.data().claimedOn)).getTime(),
                    credential: CURR_USERPROFILE.credential,
                    specialty: CURR_USERPROFILE.specialty||""
                }
                //
                var a = document.createElement('A');
                a.href=`/generate-certificate?learner_name=${creditGenPacket.learner_name}&credits_claimed=${creditGenPacket.credits_claimed}&credits_claimedon=${creditGenPacket.credits_claimedon}&credential=${creditGenPacket.credential}&specialty=${creditGenPacket.specialty}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                //
            }else{
                showNotification("Certificate not found","","",5400);
            }
        }).catch((error)=>{
            showNotification("Certificate fetch error", error, "",5400);
        });
        //
    }
    function LaunchLearnerPlusTranscript(certificateId){
        console.log(certificateId);
        firebase.firestore().collection('certificates').doc(CURR_USER.uid).collection(CURR_REFLECTIONKIT).doc(certificateId).get().then((doc)=>{
            if (doc.exists){
                // let's get the certificate response id
                var certificateResponseId = doc.data().evaluation.response_id;
                // 
                if (CURR_STRIPESTATUS){
                    window.location.href = `/report-reflectivelearningtranscript?cert_response_id=${certificateResponseId}`;
                }else{
                    showValidationModal('⚠️ Only available to subscribers ⚠️', `Learner+ transcripts showcase your learning impacts and reflective credit-earning journey. Plus, if you plan on submitting your learning expenses for reimbursement, you'll want to include your Learner+ transcripts!<br/><a href="${LearnerPlusTranscriptFeatureImagePath}" target="_blank"><img src="${LearnerPlusTranscriptFeatureImagePath}" class="w-full h-auto rounded-lg shadow-lg hover:border hover:border-blue-500 hover:border-4"></a><br/>Click below to unlock unlimited reflections &rArr; credits &rArr; certificates &amp; transcripts for $50/yr only.`, `Subscribe now`,
                    function(){
                        mixpanel.track('Click to Subscribe for Transcripts', {CURR_STRIPEPACKET});
                        analytics.track('learnerplus_click_to_subscribe_for_transcripts', {CURR_STRIPEPACKET});
                        performTask("start-subscription");
                    }, 
                    'Not now', 
                    null);
                }
            }else{
                showNotification("Invalid certificate - transcript not found","","",5400);
            }
        }).catch((error)=>{
            showNotification("Certificate fetch error", error, "",5400);
        });
    }
    // https://colearning.typeform.com/to/HotrLCrF#learner_uid=xxxxx&learner_email=xxxxx&learner_credit_balance=xxxxx
    function LaunchPrecertEvaluation(callback){
        if (CURR_LEARNERSTATE.creditBalance < 1){
            showNotification('No credits to claim', 'You do not have enough credits to claim and generate a certificate!', 'error', 3600);
            return callback(false);
        }
        tfPopup = createPopup('HotrLCrF',{
            hidden:{
                learner_uid: CURR_USER.uid,
                learner_email: CURR_USERPROFILE.email,
                learner_credit_balance: CURR_LEARNERSTATE.creditBalance + ".0"
            },
            onSubmit: function(submitPayload){
                console.log('form submitted, download certificate', submitPayload);
                callback(submitPayload);
            },
            onClose: function(){
                console.log('form closed - certificate not downloaded');
                callback(false);
            }
        });
        tfPopup.open();
        mixpanel.track('Certificate Evaluation Started', {CURR_USERPROFILE});
        analytics.track('learnerplus_certificate_evaluation_started', {CURR_USERPROFILE});
    }

    function LaunchPrecertEvaluationFlow(){
        LaunchPrecertEvaluation((submitPayload)=>{
            if (submitPayload){
                tfPopup.close();
                ConvertCreditsToCertificate(submitPayload, (claimStatusAndCertificateId)=>{
                    // download 
                    console.log(claimStatusAndCertificateId);
                    mixpanel.track('Certificate Evaluation Completed', {CURR_USERPROFILE, claimStatusAndCertificateId});
                    analytics.track('learnerplus_certificate_evaluation_completed', {CURR_USERPROFILE, claimStatusAndCertificateId});
                    //
                    if (claimStatusAndCertificateId.status === "success"){
                        GenerateAndDownloadCertificate(claimStatusAndCertificateId.certificateId);
                    }else{
                        showNotification("Error in certificate generation","","",3600);
                    }
                });
            }
        });
    }

    // This function converts credits to certificates based on validation
    function ConvertCreditsToCertificate(tfCertPacket, callback){
        // finalize-claim
        var payloadForClaim = {
            uid: CURR_USER.uid,
            reflectionkit: CURR_REFLECTIONKIT,
            certEvalPacket: tfCertPacket,
            reflection_ids: CURR_LEARNERSTATE.reflection_ids    
        }
        //
        axios.post(`/finalize-claim`, payloadForClaim)
            .then((response) => {
                console.log(response);
                return callback(response.data);
            })
            .catch((error) => {
                console.error('Error finalizing claim: ', error);
                return callback({error});
            });
    }

    function Rewardful_CreateAffiliate(callback){
        axios.get(`/rewardful/create-affiliate?uid=${CURR_USER.uid}&firstname=${CURR_USERPROFILE.firstname}&lastname=${CURR_USERPROFILE.lastname}&email=${CURR_USERPROFILE.email}`)
            .then((response) => {
                console.log(response);
                return callback(response.data);
            })
            .catch((error) => {
                //console.error('Error creating affiliate: ', error);
                return callback({error});
            });
    }

    // UI Utility Functions
    function selectDropdownOption(dropdownId, valueToSelect) {
        let selectElement = document.getElementById(dropdownId);
        selectElement.value = valueToSelect; // This will make the browser select the option that has this value
        
        // Optionally, trigger any 'change' event listeners attached to the dropdown
        selectElement.dispatchEvent(new Event('change'));
    }

    function showValidationModal(modalTitle, modalContent, modalActionLabel, modalActionCallback, cancelLabel, modalCancelCallback){
        document.getElementById('validationmodal-title').innerHTML = modalTitle;
        document.getElementById('validationmodal-content').innerHTML = modalContent;
        document.getElementById('validationmodal-action').innerHTML = modalActionLabel;
        if (cancelLabel === ''){
            document.getElementById('validationmodal-cancelbtn').classList.add('hidden');
        }else{
            document.getElementById('validationmodal-cancelbtn').classList.remove('hidden');
        }
        document.getElementById('validationmodal-cancelbtn').innerHTML = cancelLabel || "Cancel";
        document.getElementById('validationmodal-actionbtn').onclick = modalActionCallback;
        if (modalCancelCallback){
            document.getElementById('validationmodal-cancelbtn').onclick = modalCancelCallback;
        }else{
            document.getElementById('validationmodal-cancelbtn').onclick = ()=>{
                performTask('validationmodal-dismiss');
            };
        }
        document.getElementById('validationmodal').classList.remove('hidden');
    }

    var notificationTimeout = false;
    function showNotification(notificationTitle, notificationContent, notificationType, notificationDuration){
        document.getElementById('appnotifier-title').innerHTML = notificationTitle;
        document.getElementById('appnotifier-content').innerHTML = notificationContent;
        document.getElementById('appnotifier').classList.remove('opacity-0', 'scale-95', 'hidden');
        document.getElementById('appnotifier').classList.add('opacity-100', 'scale-100');
        notificationTimeout = setTimeout(function(){
            performTask('appnotifier-dismiss');
        }, notificationDuration);
    }

    function showLoadingOverlay(loadingMessage){
        if (loadingMessage){
            document.getElementById('axios_loading_message').innerHTML = loadingMessage
        }
        document.getElementById('axios_loading_overlay').classList.remove('hidden');

    }

    function hideLoadingOverlay(){
        document.getElementById('axios_loading_overlay').classList.add('hidden');
    }

    function UI_BindActions(){
        // Show the overlay when a request starts
        axios.interceptors.request.use(function (config) {
            showLoadingOverlay();
            return config;
        }, function (error) {
            hideLoadingOverlay()
            return Promise.reject(error);
        });

        // Hide the overlay when a response is received
        axios.interceptors.response.use(function (response) {
            hideLoadingOverlay()
            return response;
        }, function (error) {
            hideLoadingOverlay();
            return Promise.reject(error);
        });
        //

        document.querySelector('#current-tab-selector').addEventListener('change', (event)=>{
            document.querySelectorAll('.hubtab-btn').forEach(element => {
                element.classList.remove('border-indigo-500','text-indigo-600');
                element.classList.add('border-transparent','text-gray-500');
            })
            document.querySelectorAll('.learnerhub-section').forEach(element => {
                element.classList.add('hidden');
            });
            document.getElementById(document.querySelector('#current-tab-selector').value).classList.remove('hidden');
        });

        document.getElementById('learnerplus').addEventListener('click', function(event) {
            // Check if the clicked element has the class 'ui-action-btn'
            if (event.target.classList.contains('ui-action-btn')) {
              event.preventDefault();
              var uiTask = event.target.getAttribute('data-task');
              //
              if (uiTask.indexOf("showtab-") !== -1){
                var tabBtnClicked = event.target;
                // default the other tabs and sections to hidden
                document.querySelectorAll('.hubtab-btn').forEach(element => {
                    element.classList.remove('border-indigo-500','text-indigo-600');
                    element.classList.add('border-transparent','text-gray-500');
                })
                document.querySelectorAll('.learnerhub-section').forEach(element => {
                    element.classList.add('hidden'); 
                });
                // now lets activate the right guys
                var elemToShow = uiTask.split("showtab-")[1];
                selectDropdownOption('current-tab-selector', elemToShow);
                document.getElementById(elemToShow).classList.remove('hidden');
                tabBtnClicked.classList.add('border-indigo-500','text-indigo-600')
                tabBtnClicked.classList.remove('border-transparent','text-gray-500');
                return;
              }
              //
              performTask(uiTask);
            }
        });

        document.getElementById('appnotifier-close-btn').addEventListener('click', function(event) {
            performTask('appnotifier-dismiss');
        });
    }

    function performTask(uiTask){
        //
        if (uiTask.indexOf("generate-certificate::") === 0){
            var certificateId = uiTask.split("generate-certificate::")[1];
            return GenerateAndDownloadCertificate(certificateId);
        }
        if (uiTask.indexOf("launch-transcript::") === 0){
            var certificateId = uiTask.split("launch-transcript::")[1];
            return LaunchLearnerPlusTranscript(certificateId);
        }
        if (uiTask === "email-affiliate-link"){
            return window.open(`mailto:?subject=Join me on Learner%2B&body=Hi,%0A%0AI've been using Learner%2B to reflect anytime and unlock CME%2FCE credits and I think you might find it useful. Here's a link to get started: ${encodeURIComponent(CURR_USERPROFILE.rewardful_affiliate.links[0].url)} %0A%0ABest regards,%0A[Your Name]`);
        }
        if (uiTask === "text-affiliate-link"){
            return window.open(`sms:?body=Hi, I've been using Learner+ and I think you might find it useful. Here's a link to get started: ${CURR_USERPROFILE.rewardful_affiliate.links[0].url}`);
        }
        if (uiTask.indexOf("learn-more-why-subscribe") === 0){
            return showValidationModal('Why Subscribe to Learner+?', `<ul class="list-disc list-inside font-semibold text-left">
                    <li>Reflect ANYTIME &amp; EVERYWHERE learning happens</li>
                    <li>Launch in your browser or via text/SMS</li>
                    <li>Unlock unlimited CME/CE credits &amp; certificates</li>
                    <li>Generate learning transcripts to simplify reimbursements</li>
                    <li>and more <strong class="font-bold text-learner-blue">for just $50/year</strong></li>
                    <li>PS: Your employer may reimburse this subscription under your CME/CE budget!</li>
                </ul>`, 
                `Subscribe Now`,()=>{
                    performTask('smart-manage-subscription');
                }, 
                'Not now', null);
        }
        //
        switch (uiTask){
            case "start-default-reflection":
                window.location.href = '/'+CURR_REFLECTIONKIT;
                break;
            case "show-all-reflections":
                showNotification("Subscribe to Learner+", `You must be a subscriber to view your full reflection history<br/><br/><button type="button" class="rounded bg-indigo-50 px-2 py-1 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 ui-action-btn" data-task="start-subscription">Subscribe Now</button>`, "info", 6300);
                break;
            case "prompt-certificatepacket-generation":
                var askCertClaim = false;
                var askCertClaimMessage = ``
                if (!askCertClaim && CURR_LEARNERSTATE.creditBalance <= 3){
                    askCertClaimMessage = `The number of credits you claim will appear on your certificate. Do you want to claim now or wait until you’ve reflected more? (You cannot combine certificates afterward).`
                    askCertClaimMessage = `By claiming credits, Learner+ will convert your earned credits into a downloadable certificate. <strong>You may prefer to continue earning credits and claim them all at a later date.</strong>`;
                    askCertClaim = true;
                }
                if (!askCertClaim && [...new Set(CURR_LEARNERSTATE.reflections.map(r=>r.ipoc_context))].length === 1 && CURR_LEARNERSTATE.creditBalance <= 5){
                    askCertClaimMessage = `Are you sure you're done reflecting on all the ${[...new Set(CURR_LEARNERSTATE.reflections.map(r=>r.ipoc_context))][0]} learning moments for now? Click claim now or wait until you've reflected more.`
                    askCertClaim = true;
                }
                if (askCertClaim){
                    showValidationModal('Are you sure you want to claim this credit now?', askCertClaimMessage, `Claim ${CURR_LEARNERSTATE.reflections.length} credit${CURR_LEARNERSTATE.reflections.length>1?'s':''}`,
                    function(){
                        performTask("validationmodal-dismiss");
                        LaunchPrecertEvaluationFlow();
                    }, 
                    'Not now', 
                    null);
                }else{
                    LaunchPrecertEvaluationFlow();
                }
                break;
            case "update-learner-profile":
                updateProfile((updateError)=>{
                    console.log(`profile updated`, updateError);
                });
                break;
            case "text-me":
                axios.get('/tasks/text-me', {
                    params: {
                        uid: CURR_USER.uid,
                        message: '👋 Hi, this is Learner+! You can text this number to spark a reflection anytime :). Just say "Hi"'
                    }
                })
                break;
            case "smart-manage-subscription":
                if (CURR_STRIPEPACKET.subscription_status === 'active'){
                    mixpanel.track('Manage Subscription', {CURR_STRIPEPACKET});
                    analytics.track('learnerplus_manage_subscription', {CURR_STRIPEPACKET});
                    performTask('manage-subscription');
                }else{
                    mixpanel.track('Click to Subscribe', {CURR_STRIPEPACKET});
                    analytics.track('learnerplus_click_to_subscribe', {CURR_STRIPEPACKET});
                    performTask('start-subscription');
                }
                break;
            case "start-subscription":
                // check if the subscription is active, otherwise take them to the subscription page
                window.location.href = '/create-checkout-session?email='+CURR_USERPROFILE.email+'&uid='+CURR_USER.uid;
                break;
            case "manage-subscription":
                window.location.href = '/manage-subscription?customer='+CURR_STRIPEPACKET.customer + "&subscription="+CURR_STRIPEPACKET.subscription + "&uid="+CURR_USER.uid;
                break;
                break;
            case "validationmodal-dismiss":
                // unbind and close
                document.getElementById('validationmodal-actionbtn').onclick = null; 
                document.getElementById('validationmodal-cancelbtn').onclick = null; 
                document.getElementById('validationmodal').classList.add('hidden');
                break;
            case "appnotifier-dismiss":
                clearTimeout(notificationTimeout);
                document.getElementById('appnotifier').classList.remove('opacity-100', 'scale-100');
                document.getElementById('appnotifier').classList.add('opacity-0', 'scale-95', 'hidden');
                break;
            case "logout-curruser":
                if (confirm('Are you sure you want to logout?')){
                    firebase.auth().signOut().then(() => {
                        analytics.reset();
                        location.reload();
                    }).catch((error) => {
                        // An error happened.
                        showNotification('Logout Error', 'There was an error logging out - '+error+'.', 'error', 3000);
                    });
                }
                break;
            default:
                // no-op
                break;
        }
        
    }
    //
    const setSelectValue = (paramValue, selectElementId) => {
        const selectElement = document.getElementById(selectElementId);
        if (selectElement) {
            const option = selectElement.querySelector(`option[value="${paramValue}"]`);
            if (option) {
                selectElement.value = paramValue;
            }
        }
    };

    

})();
