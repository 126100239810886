export function getQueryParamsAsObject() {
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObj = {};

    // Iterate over all keys and values
    for (const [key, value] of queryParams) {
        // Store them in an object
        paramsObj[key] = value;
    }

    return paramsObj;
}
export function getQueryParamsAsString() {
    return new URL(window.location.href).search; // This includes the leading '?'
}

export function removeURLParameters(params) {
    const urlParams = new URLSearchParams(window.location.search);
    params.forEach(param => {
        if (urlParams.has(param)) {
            urlParams.delete(param);
        }
    });
    if (urlParams.toString() === '') {
        window.history.replaceState({}, '', `${location.pathname}`);
    }else{
        window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
    }
}